body {
	font-size: 14px !important;
}

span {
	font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
	font-size: 1.1rem;
}
.ui.input>input {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: none;
	height: 1.5rem;
}


.lalTestButton {
	line-height: 1.21428571rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
	font-size: 1.1rem;
    padding: 1rem;
	border: 1px solid rgba(34,36,38,.15);
	border-radius: .28571429rem;
	background: #226daa;
	color: #fff;
}